import { SportProvider } from '../../interfaces/SportProvider';
import { Event, EventAttributes, EventExternalMatchSource, HeroImage } from '../../interfaces/Event';
import { dateFromFirebaseString, FirebaseItemTranslator } from './FirebaseItemResolver';
import { FirebaseBackedEvent } from '../FirebaseBackedEvent';
import firebase from 'firebase';
import { EventRegistrationAttributes } from '../../interfaces/EventRegistration';
import { TeamColor } from '../../interfaces/TeamAttributes';

export class FirebaseEventTranslator implements FirebaseItemTranslator<Event> {
    id: string;
    database: firebase.database.Database;
    private sportProvider: SportProvider;

    constructor(id: string, sportProvider: SportProvider, database: firebase.database.Database) {
        this.id = id;
        this.sportProvider = sportProvider;
        this.database = database;
    }
    translate(
        snapshot: firebase.database.DataSnapshot,
        onSuccess: (Event) => void,
        onFailure: (string) => void
    ) {
        const val = snapshot.val() || {};
        const name = val['name'];
        if (name) {
            const externalMatchSourceID = val['externalMatchSource'];
            var externalMatchSource = EventExternalMatchSource.none;
            if (externalMatchSourceID === 'REF_TOWN') {
                externalMatchSource = EventExternalMatchSource.refTown;
            } else if (externalMatchSourceID === 'REV_SPORT') {
                externalMatchSource = EventExternalMatchSource.revSport;
            }
            const competitionStartDateString = val['competitionStartDate'];
            const competitionEndDateString = val['competitionEndDate'];
            var displayCompetitionDates = false;
            var competitionStartDate = new Date();
            var competitionEndDate = new Date();
            if (competitionStartDateString && competitionEndDateString) {
                const parsedStartDate = dateFromFirebaseString(competitionStartDateString);
                const parsedEndDate = dateFromFirebaseString(competitionEndDateString);
                if (parsedStartDate && parsedEndDate) {
                    competitionStartDate = parsedStartDate;
                    competitionEndDate = parsedEndDate;
                    displayCompetitionDates = true;
                }
            }

            const regsistrationObject = val['registration'] || {};
            const numberOfEntries = regsistrationObject['numberOfEntries'];

            const categoryString = val['category'];
            const divisionString = val['division'];

            const category = categoryString
                ? this.sportProvider.teamCategories.find((teamCategory) => {
                      return teamCategory.databaseValue === categoryString;
                  })
                : undefined;

            const division =
                category && divisionString
                    ? category.divisions.find((division) => {
                          return division.databaseValue === divisionString;
                      })
                    : undefined;

            const colorData = val['color'];
            var eventColor: TeamColor | undefined = undefined;
            if (colorData) {
                const red = colorData['red'];
                const green = colorData['green'];
                const blue = colorData['blue'];
                if (typeof red === 'number' && typeof green === 'number' && typeof blue === 'number') {
                    if (red >= 0 && red <= 256 && green >= 0 && green <= 256 && blue >= 0 && blue <= 256) {
                        eventColor = { red, green, blue };
                    } 
                } 
            }

            const heroImageURL = val['heroImageURL'];
            const heroImageDataPath = val['heroImageDataPath'];
            var heroImage: HeroImage | undefined = undefined;
            if (heroImageURL) {
                heroImage = { url: heroImageURL };
            } else if (heroImageDataPath) {
                heroImage = { dataProviderReferenceString: heroImageDataPath };
            }

            var eventAttributes: EventAttributes = {
                name: name,
                displayCompetitionDates,
                competitionDateRange: {
                    startDate: competitionStartDate,
                    endDate: competitionEndDate,
                },
                heroImage,
                color: eventColor,
            };
            eventAttributes.category = category;
            eventAttributes.division = division;

            if (typeof numberOfEntries === 'number') {
                var registrationPrice = regsistrationObject['price'];
                if (typeof registrationPrice !== 'number') {
                    registrationPrice = undefined;
                }
                const registrationAttributes: EventRegistrationAttributes = {
                    maxNumberOfEntries: numberOfEntries,
                    price: registrationPrice,
                };
                const event = new FirebaseBackedEvent(
                    this.id,
                    this.sportProvider,
                    this.database,
                    eventAttributes,
                    externalMatchSource,
                    registrationAttributes
                );
                onSuccess(event);
            } else {
                const event = new FirebaseBackedEvent(
                    this.id,
                    this.sportProvider,
                    this.database,
                    eventAttributes,
                    externalMatchSource,
                    undefined
                );
                onSuccess(event);
            }
        } else {
            onFailure(`missing event name for ${this.id}`);
        }
    }
}
