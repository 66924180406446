import { SportProvider } from '../../models/interfaces/SportProvider';
import { Container, Row, Button, Col } from 'react-bootstrap';
import { SponsorBanner } from './SponsorBanner';
import { FeaturedEventsView } from '../events/FeaturedEventsView';
import { useHistory } from 'react-router-dom';
import { User } from '../../models/interfaces/User';

interface Props {
  sportProvider: SportProvider;
  user?: User;
}

export default function WPAusLandingPage({ sportProvider, user }: Props) {
  const history = useHistory()
  return (
    <Container>
      <FeaturedEventsView sportProvider={sportProvider} user={user} />
      <Row className='justify-content-center pb-4'>
        <Button onClick={() => { history.push('/all_wpa') }}>More Tournamnets</Button>
      </Row>
      <Row>
        <SponsorBanner sponsors={getSponsors()} />
      </Row>
    </Container>
  );
}

function getSponsors() {
  return [
    {
      name: 'Ord Minnett',
      image: `${process.env.PUBLIC_URL}/wpa_sponsor_ord_minnett.jpeg`,
      link: 'https://ords.com.au'
    },
    {
      image: `${process.env.PUBLIC_URL}/wpa_sponsor_kap7_au.jpeg`,
      name: "KAP7 Australia",
      link: "https://kap7.com.au"
    },
    {
      image: `${process.env.PUBLIC_URL}/wpa_sponsor_wa.png`,
      name: "Western Australia",
      link: "https://www.westernaustralia.com/en/home"
    },
    {
      image: `${process.env.PUBLIC_URL}/wpa_sponsor_dlgsc.png`,
      name: "Department of Local Government, Sport and Cultural Industries",
      link: "https://www.dlgsc.wa.gov.au"
    },
    {
      name: 'Sport Australia',
      image: `${process.env.PUBLIC_URL}/wpa_sponser_aus_sports.png`,
      link: 'https://www.ausport.gov.au'
    },
    {
      name: 'Australian Olympic Committee',
      image: `${process.env.PUBLIC_URL}/wpa_sponsers_aoc.jpeg`,
      link: 'https://www.olympics.com.au/'
    },
    {
      name: "Australian Institute of Sport",
      image: `${process.env.PUBLIC_URL}/wpa_sponsor_ais.png`,
      link: "https://www.ais.gov.au"
    },
    {
      name: 'NSW Institute of Sport',
      image: `${process.env.PUBLIC_URL}/wpa_sponsor_nswis.jpeg`,
      link: 'https://www.nswis.com.au'
    },
    {
      name: 'Queensland Academy of Sport',
      image: `${process.env.PUBLIC_URL}/wpa_sponsor_queensland_is.jpeg`,
      link: 'https://www.qasport.qld.gov.au'
    },
    {
      name: 'WAIS',
      image: `${process.env.PUBLIC_URL}/wpa_sponsor_wais.jpeg`,
      link: 'https://wais.org.au'
    },
    {
      name: 'News Corp Australia',
      image: `${process.env.PUBLIC_URL}/wpa_sponsor_nca.jpeg`,
      link: 'https://www.dailytelegraph.com.au/sport/live-streams'
    },
    {
      name: 'New Balance',
      image: `${process.env.PUBLIC_URL}/wpa_sponsor_new_balance.png`,
      link: 'https://www.newbalance.com.au'
    },
    {
      name: 'Belgravia Group',
      image: `${process.env.PUBLIC_URL}/wpa_sponsor_belgravia_group.png`,
      link: 'https://belgraviagroup.com.au'
    },
    {
      name: "Hello.",
      image: `${process.env.PUBLIC_URL}/wpa_sponsor_hello.jpg`,
      link: "https://helloperiod.com"
    },
    {
      name: "Rent a space",
      image: `${process.env.PUBLIC_URL}/wpa_sponsor_rent_a_space.jpg`,
      link: "https://rentaspace.com.au"
    },
    {
      name: 'Refbook',
      image: `${process.env.PUBLIC_URL}/wpa_sponsor_refbook.png`,
      link: 'https://refbook.online'
    }
  ]
}
